.banner-our-phases {
    background-color: rgb(16, 16, 21);
    color: white;
}
.banner-our-phases img{
    height: 60vh;
}
.banner-our-phases p {
    color: white;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 27px;
}