.action-bar{
	background-color: #101015;
	color: #bbbbbb;
	font-size: 18px;
}
.action-bar ul{
	list-style-type: none;
}
.action-bar li {
  display: inline-block;
}
.action-bar a{
	color: #bbbbbb;
}
@media (max-width: 750px) {
	.action-bar {
		font-size: 17px;
	}
}