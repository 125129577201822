.banner-home{
    color: white;
    background-image: url('../../img/fondo-banner-home.jpg');
}
.banner-home h1, h4{
    font-size: 40px;
    line-height: 50px;
    font-weight: 500;
    letter-spacing: 0px;
}
.banner-home h2{
    font-size: 30px;
    line-height: 40px;
    font-weight: 400;
    letter-spacing: 0px;
}
.banner-home h3{
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    letter-spacing: 0px;
}
.form-control{
    border-radius: 0px;
}
.btn{
    background-color: #0089F7;
    color: white;
    border-radius: 0;
}