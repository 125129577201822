.services-prices .prices-card{
    background-color: #f5f6f6;
    border: 1px solid #0dc9ff;
}
.services-prices .prices-card p {
    color: #626262;
    font-size: 16px;
    line-height: 27px;
    font-weight: 400;
    height: 100px;
}
.services-prices .prices-card h3 {
    color: #161922;
    font-size: 26px;
    line-height: 30px;
    font-weight: 600;
    letter-spacing: 0px;
}
.services-prices .prices-card h4 {
    font-weight: 600;
    font-size: 22px;
    color: #161922;
}
.services-prices .prices-card h5 {
    font-size: 14px;
    color: #626262;
}