.terms-description h1 {
    color: #161922;
    font-size: 40px;
    line-height: 50px;
    font-weight: 500;
    letter-spacing: 0px;
}

.terms-description h2 {
    color: #161922;
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    letter-spacing: 0px;
}

.terms-description p {
    color: #626262;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
}