/* Footer.css */
footer {
    background-color: rgb(16, 16, 21);
    color: #bababa;
    padding: 20px 0;
}

footer a {
    font-size: 30px;
    line-height: 40px;
    font-weight: 400;
    letter-spacing: 0px;
    text-decoration: none;
    color: white;
}

footer p {
    color: #d1d1d1;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
}
footer ul{
    list-style-type: none;
}
footer li, li a{
    color: #d1d1d1;
    font-size: 18px;
    font-weight: 400;
}
footer h4{
    color: white;
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    letter-spacing: 0px;
}
footer h5{
    font-size: 18px;
}
.arrow-up {
    color: #bababa;
    text-decoration: none;
    font-size: 24px;
}
.arrow-up:hover {
    color: #ffffff;
}