.stacks h2 {
    font-size: 30px;
    line-height: 40px;
    font-weight: 400;
    letter-spacing: 0px;
}

.stacks p {
    color: #626262;
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
    letter-spacing: 1px;
}