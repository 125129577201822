.stats {
    background-color: #2196F3;
}

.stats ul {
    list-style-type: none;
}

.stats li span {
    color: #fff;
    font-size: 40px;
    line-height: 50px;
    font-weight: 500;
    letter-spacing: 0px;
}
.stats h4{
    font-weight: 400;
    font-size: 28px;
    letter-spacing: 0px;
    color: #fff;
    line-height: 42px;
    padding-top: 5px;
}