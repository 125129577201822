.services-banner {
    background-color: #e7fafb;
}
.services-banner h1{
    font-size: 40px;
    line-height: 50px;
    font-weight: 500;
    letter-spacing: 0px;
}
.services-banner h2{
    color: #5f6271;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    letter-spacing: 0px;
    padding-bottom: 5px;
    border-bottom: 2px solid #0dc9ff;
}
.services-banner p{
    color: #626262;
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
    letter-spacing: 1px;
}