.services-description li {
    list-style-type: none !important;
    position: relative;
}
.services-description li::before {
    content: "";
    width: 18px;
    height: 18px;
    background-image: url(https://dist.neo4j.com/wp-content/uploads/20231024070824/check-circle-icon.svg);
    position: absolute;
    left: -1.5rem;
    top: 4px;
    background-repeat: no-repeat;
}
.services-description h3 {
    color: #161922;
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    letter-spacing: 0px;
}
.services-description p {
    color: #626262;
    font-size: 16px;
    line-height: 27px;
    font-weight: 400;
}