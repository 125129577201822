.contact-description h1{
    color: #fff;
    font-size: 40px;
    line-height: 50px;
    font-weight: 500;
    letter-spacing: 0px;
}
.contact-description p{
    color: #fff !important;
    font-size: 18px;
    font-weight: 400;
}
.bg-contact{
    background-color: #018BFF;
}