.content-our-phases{
    color: #626262;
    font-size: 16px;
    line-height: 27px;
    font-weight: 400;
}
.content-our-phases h2{
    color: #161922;
    font-size: 40px;
    line-height: 50px;
    font-weight: 500;
    letter-spacing: 0px;
}
.content-our-phases h3{
    color: #161922;
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    letter-spacing: 0px;
}